<template>
  <div class="contact-info-ticket">
    <div>
      <!--  filter  -->
      <b-card-code
        :title="$t('partnerView.textFilters')"
        class="filter-control user-management mb-0"
      >
        <!-- advance search input -->
        <b-row>
          <b-col
            md="7"
            lg="5"
          >
            <b-form-group>
              <label>{{ $t('partnerView.labelSearchUserByField') }}</label>
              <b-input-group class="input-filter">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom input-height"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder="
                    searchSelected === 'email'
                      ? $t('partnerView.placeholderSearchByEmail')
                      : $t('partnerView.placeholderSearchById')
                  "
                  :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                  class="d-inline-block input-height"
                  :class="searchSelected === 'email' ? 'input-search' : ''"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
                <b-button
                  v-if="searchSelected === 'email'"
                  variant="outline-secondary"
                  class="btn-filter-search input-height"
                  :class="isActiveBtn ? 'active-btn-search' : null"
                  @click.prevent="handleSubmitSearch"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            offset-md="3"
            offset-lg="0"
            md="5"
            lg="4"
            class="px-md-0"
          >
            <b-form-group class="group-input">
              <label> {{ $t('partnerView.labelVerifyAt') }} </label>
              <calendar
                v-model="rangeDate"
                :placeholder="$t('partnerView.placeholderVerifyAt')"
                @input="handleDateChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="3"
            class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <b-form-group class="w-100">
              <label> {{ $t('balance.textStatus') }} </label>
              <v-select
                v-model="statusSelected"
                class="input-height status-select"
                :placeholder="$t('partnerView.placeholderSelectStatus')"
                :options="statusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
        </b-row>

      </b-card-code>
      <!-- table -->
      <vue-good-table
        mode="remote"
        class="table-user-info"
        :columns="columns"
        :rows="listItemUserInfo"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: totalUserInfo > 0
        }"
        :sort-options="{
          enabled: totalUserInfo > 0
        }"
        :is-loading="loadingPartnerShip"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/member/${props.row.localUserId}`"
          >
            <b-img
              rounded
              class="ads-avt-img mr-1"
              :src="userInfo()"
            />
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0 underline">
                {{ props.row.fullName || '' }}
                <span v-if="props.row.whiteList">
                  <b-img
                    rounded
                    class="ads-avt-img mr-1"
                    :src="
                      require('@/assets/images/pages/payment/icon-white-list.svg')
                    "
                  />
                </span>
              </p>
              <!--              <span class="text-placeholder">{{ props.row.email || '' }}</span>-->
            </div>
          </a>

          <!-- country -->
          <div v-else-if="props.column.field === 'country'">
            <span v-if="props.row.country">
              {{ props.row.country }}
            </span>
            <span v-else> N/A </span>
          </div>

          <div v-else-if="props.column.field === 'contactInfo.phone'">
            <span v-if="phone(props.row)">
              {{ showPhoneNumber(phone(props.row)) }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- contactInfo.facebook -->
          <div v-else-if="props.column.field === 'contactInfo.facebook'">
            <a
              v-if="facebookContact(props.row)"
              :href="facebookContact(props.row)"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue crop-text"
            >
              {{ facebookContact(props.row) }}
            </a>
            <span v-else> N/A </span>
          </div>

          <!-- contactInfo.whatsApp -->
          <div v-else-if="props.column.field === 'contactInfo.whatsApp'">
            <span>
              {{ whatsAppContact(props.row) }}
            </span>
          </div>

          <!-- contactInfo.telegram -->
          <div v-else-if="props.column.field === 'contactInfo.telegram'">
            <span>
              {{ telegramContact(props.row) }}
            </span>
          </div>

          <!-- contactInfo.telegram -->
          <div v-else-if="props.column.field === 'products.productURLs'">
            <div v-if="productLinkList(props.row).length > 0">
              <ul
                v-for="(item, index) in productLinkList(props.row)"
                :key="index"
                class="p-0"
              >
                <li>
                  <p
                    v-b-tooltip.v-light="item.productUrl"
                    class="
                      text-blue
                      underline
                      cursor-pointer
                      tag-content
                      content-product-link
                      m-0
                    "
                    @click="goToUrl(item.productUrl)"
                  >
                    {{ item.productUrl }}
                  </p>
                  <span>
                    {{ targetMarketsToString(item) }} - {{ statusProductLink(item.status) }}
                  </span>
                </li>
              </ul>
            </div>
            <span v-else> N/A </span>
          </div>

          <!-- plans.type -->
          <div v-else-if="props.column.field === 'plans.type'">
            <span>
              {{ currentPlan(props.row) }}
            </span>
          </div>

          <!-- createdAt -->
          <div v-else-if="props.column.field === 'createdAt'">
            <span v-if="createdDate(props.row)">
              {{ createdDate(props.row) }}
            </span>
            <span v-else>N/A</span>
          </div>

          <!-- contactInfo.verification.status -->
          <div
            v-else-if="props.column.field === 'contactInfo.verification.status'"
          >
            <span
              v-if="statusUserInfo(props.row)"
              class="text-status"
              :class="statusVariant(props.row)"
            >
              {{ statusUserInfo(props.row) }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- contactInfo.verification.rejectedReason -->
          <div
            v-else-if="
              props.column.field === 'contactInfo.verification.rejectedReason'
            "
          >
            <div
              v-if="rejectReason(props.row)"
              class="reason-reject-content"
              v-html="rejectReason(props.row)"
            />
            <span v-else> N/A </span>
          </div>

          <!-- reviewedBy -->
          <!--          <div-->
          <!--            v-else-if="-->
          <!--              props.column.field === 'contactInfo.verification.verifiedBy'-->
          <!--            "-->
          <!--          >-->
          <!--            <span v-if="idAdmin(props.row)">{{ idAdmin(props.row) }}</span>-->
          <!--          </div>-->

          <!-- contactInfo.verification.at -->
          <div v-else-if="props.column.field === 'contactInfo.verification.at'">
            <span
              v-if="approvedDate(props.row)"
            >{{ approvedDate(props.row) }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- screenshots -->
          <div v-else-if="props.column.field === 'screenshots'">
            <div v-if="hasScreenshot(props.row) || dashboardScreenshot(props.row)">
              <div class="d-flex">
                <div
                  v-for="(img, index) in listScreenshot(props.row)"
                  :key="index"
                  class="img-store-screenshot"
                  @click="previewImgStoreScreenshot(img)"
                >
                  <b-img
                    :src="img"
                    alt="img uploaded"
                  />
                  <div class="gallery-item-info">
                    <feather-icon
                      size="21"
                      icon="EyeIcon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span v-else> N/A </span>
          </div>

          <!-- Column: amId -->
          <div v-else-if="props.column.field === 'amId'">
            <span
              v-if="props.row.amId"
              class="content-status"
            >
              {{ props.row.amId }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- actions -->
          <div v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="approveUserInfo(props.row, productLinkList(props.row))"
              >
                <span>Approve</span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="openPopupReject(props.row, productLinkList(props.row))"
              >
                <span>Reject</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalUserInfo > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap justify-content-between p-2">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap">
                of {{ totalUserInfo }} user(s) info
              </span>
            </div>
            <div v-if="totalUserInfo > pageLength">
              <b-pagination
                :total-rows="totalUserInfo"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0 pagination-table"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
        >
          <no-data-table-image class="my-5" />
        </div>
      </vue-good-table>
    </div>
    <reject-info-popup
      :info-user-detail="infoUserDetail"
      :show-side-bar.sync="isShowRejectInfoSideBar"
      :product-link-reject="productLinkReject"
      @fetch-list-member-info-verify="fetchListMemberInfoVerify"
    />

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewStoreScreenshot"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
/* eslint-disable no-underscore-dangle */
import {
  BPagination,
  BSpinner,
  BImg,
  BDropdown,
  BDropdownItem,
  // BBadge,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import { STATUS_USER_INFO } from '@/constants'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import calendar from '@/components/Calendar.vue'
import noDataTableImage from '@/components/NoDataTableImage.vue'
import envMixin from '@/mixins/envMixin'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'
import RejectInfoPopup from './RejectInfoPopup.vue'

const { mapActions } = createNamespacedHelpers('partnerShip')
const { mapActions: mapActionsReferralProgram } = createNamespacedHelpers('referralProgram')

export default {
  name: 'MemberContactInfoTicket',

  components: {
    RejectInfoPopup,
    BPagination,
    BSpinner,
    BImg,
    BDropdown,
    BDropdownItem,
    // BBadge,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BRow,
    BCol,
    BInputGroup,
    BButton,
    BCardCode,
    vSelect,
    calendar,
    noDataTableImage,
    VueGoodTable,
    ShowScreenshotModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [toastification, envMixin],

  data() {
    return {
      currentPage: 1,
      searchTerm: '',

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: this.$t('partnerView.labelUserId'),
          value: 'localUserId',
        },
        {
          text: this.$t('partnerView.labelUserEmail'),
          value: 'email',
        },
      ],

      statusSelected: {
        label: this.$t('productLinkPage.statusReviewing'),
        value: STATUS_USER_INFO.REVIEWING,
      },
      statusOptions: [
        {
          label: this.$t('payment.balanceTransactionsTable.textAllStatus'),
          value: '',
        },
        {
          label: this.$t('billingPage.statusRejected'),
          value: STATUS_USER_INFO.REJECTED,
        },
        {
          label: this.$t('productLinkPage.statusReviewing'),
          value: STATUS_USER_INFO.REVIEWING,
        },
        {
          label: this.$t('myAdAccountsPage.textApproved'),
          value: STATUS_USER_INFO.APPROVED,
        },
      ],

      rangeDate: {
        startDate: null,
        endDate: null,
      },

      columns: [
        {
          label: this.$t('partnerView.labelUserId'),
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column-100',
        },
        {
          label: this.$t('partnerView.textUserInfo'),
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('stepGuideControl.stepVerifyInfo.labelCountry'),
          field: 'country',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column',
        },
        // {
        //   label: this.$t('stepGuideControl.stepVerifyInfo.formPrimaryContact.labelPhoneNumber'),
        //   field: 'contactInfo.phone',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column',
        // },
        // {
        //   label: 'Facebook',
        //   field: 'contactInfo.facebook',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        // {
        //   label: 'WhatsApp',
        //   field: 'contactInfo.whatsApp',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        // {
        //   label: 'Telegram',
        //   field: 'contactInfo.telegram',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: this.$t('partnerView.textProductUrl'),
          field: 'products.productURLs',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass:
            'p-1 border-left-0 border-right-0 align-middle column-product-link',
        },
        {
          label: 'Screenshots',
          field: 'screenshots',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column',
        },
        {
          label: this.$t('partnerView.textCurrentPlan'),
          field: 'plans.type',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('partnerView.textCreateAt'),
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.status'),
          field: 'contactInfo.verification.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column',
        },
        {
          label: this.$t('partnerView.labelReasonsReject'),
          field: 'contactInfo.verification.rejectedReason',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle width-column',
        },
        // {
        //   label: 'Review by',
        //   field: 'contactInfo.verification.verifiedBy',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: this.$t('partnerView.labelVerifyAt'),
          field: 'contactInfo.verification.at',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('common.textActions'),
          field: 'actions',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],

      productLinkReject: [],
      infoUserDetail: {},
      isShowRejectInfoSideBar: false,
      imgPreviewStoreScreenshot: '',
    }
  },

  computed: {
    ...mapGetters({
      listMemberContactInfo: 'partnerShip/listMemberContactInfo',
      loadingPartnerShip: 'partnerShip/loading',
      statusVerifyInfo: 'partnerShip/status',
      messageErrorVerifyInfo: 'partnerShip/message',
      statusProductMember: 'referralProgram/status',
      messageErrorUser: 'referralProgram/message',
    }),

    hasScreenshot() {
      return data => data?.contactInfo?.storeScreenshots?.length > 0
    },

    dashboardScreenshot() {
      return value => value?.exclusiveVerifyInfo?.dashboardScreenshots?.length > 0
    },

    listScreenshot() {
      return data => (this.isExclusivePartner ? data?.exclusiveVerifyInfo?.dashboardScreenshots : data?.contactInfo?.storeScreenshots)
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    // idAdmin() {
    //   return info => info?.contactInfo?.verification?.verifiedBy || 'N/A'
    // },

    rejectReason() {
      return info => info?.contactInfo?.verification?.rejectedReason
    },

    phone() {
      return data => data?.contactInfo?.phone
    },

    facebookContact() {
      return data => data?.contactInfo?.facebook
    },

    whatsAppContact() {
      return data => data?.contactInfo?.whatsApp || 'N/A'
    },

    telegramContact() {
      return data => data?.contactInfo?.telegram || 'N/A'
    },

    currentPlan() {
      return data => data?.plans?.type || 'N/A'
    },

    createdDate() {
      return value => (value.contactInfo?.createdAt
        ? new Date(value.contactInfo?.createdAt).toLocaleString('en-GB')
        : 'N/A')
    },

    approvedDate() {
      return value => (value.contactInfo?.verification?.at
        ? new Date(value.contactInfo?.verification?.at).toLocaleString('en-GB')
        : 'N/A')
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    totalUserInfo() {
      return _get(this.listMemberContactInfo, ['paging', 'total'])
    },

    listItemUserInfo() {
      return _get(this.listMemberContactInfo, ['content'])
    },

    productLinkList() {
      return val => {
        if (!val?.products?.productURLs) return []

        const productLinks = val?.products?.productURLs
        const convertedUrls = Object.keys(productLinks)
          .map(value => ({
            productUrl: value,
            ...productLinks[value],
          }))

        return convertedUrls.reverse()
      }
    },

    targetMarketsToString() {
      return item => item?.targetMarkets
        && Array.isArray(item?.targetMarkets)
        && item?.targetMarkets.length > 0
        && item?.targetMarkets.join(', ')
    },

    statusVariant() {
      const statusColor = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return status => statusColor[status?.contactInfo?.verification?.status]
    },

    statusUserInfo() {
      const statusInfo = {
        [STATUS_USER_INFO.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
        [STATUS_USER_INFO.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [STATUS_USER_INFO.REJECTED]: this.$t('billingPage.statusRejected'),
        [STATUS_USER_INFO.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
      }

      return status => statusInfo[status?.contactInfo?.verification?.status]
    },

    statusProductLink() {
      const statusInfo = {
        [STATUS_USER_INFO.NOT_FOUND]: this.$t('productLinkPage.statusNotFound'),
        [STATUS_USER_INFO.REVIEWING]: this.$t('productLinkPage.statusReviewing'),
        [STATUS_USER_INFO.REJECTED]: this.$t('billingPage.statusRejected'),
        [STATUS_USER_INFO.APPROVED]: this.$t('myAdAccountsPage.textApproved'),
      }

      return status => statusInfo[status]
    },

    rangeDates() {
      return {
        verifiedFrom: this.rangeDate.startDate,
        verifiedTo: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },
  },

  created() {
    this.fetchListMemberInfoVerify()
  },

  methods: {
    ...mapActions(['fetchListMemberInformation', 'verifyContactInformation']),
    ...mapActionsReferralProgram(['handleProductLink']),

    previewImgStoreScreenshot(img) {
      this.imgPreviewStoreScreenshot = img
      this.$refs['open-screenshot-modal'].showModal()
    },

    goToUrl(url) {
      if (url.includes('http://') || url.includes('https://')) {
        return window.open(url)
      }
      return window.open(`https://${url}`)
    },

    openPopupReject(info, productLink) {
      this.productLinkReject = productLink
      this.infoUserDetail = info
      this.isShowRejectInfoSideBar = true
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchListMemberInfoVerify()
      }
    },

    showPhoneNumber(phoneNum) {
      let result = ''
      if (phoneNum && phoneNum.split('|')) {
        const phoneElm = phoneNum.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = phoneNum.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      } else {
        result = phoneNum
      }
      return result
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListMemberInfoVerify()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListMemberInfoVerify()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListMemberInfoVerify()
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      await this.fetchListMemberInfoVerify()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListMemberInfoVerify()
    },

    async handleChangeSearchType() {
      this.searchTerm = ''
      await this.fetchListMemberInfoVerify()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchListMemberInfoVerify()
      } else if (
        searching.toString().length > 3
        && this.searchSelected !== 'email'
      ) {
        this.debounce = setTimeout(() => {
          this.fetchListMemberInfoVerify()
        }, 600)
      }
    },

    async approveUserInfo(info, productLink) {
      const productLinkReviewing = productLink.find(
        item => item.status === 'reviewing',
      )

      if (productLinkReviewing) {
        const paramsApprove = {
          id: info.localUserId,
          action: 'approve',
          productURL: productLinkReviewing?.productUrl,
        }

        await this.handleProductLink(paramsApprove)
        if (this.statusProductMember) {
          const params = {
            id: info._id,
            status: 'verify',
          }
          await this.verifyContactInformation(params)
          if (this.statusVerifyInfo) {
            this.toastSuccess(this.$t('partnerView.toastApproveUserInfoSuccess'))
            await this.fetchListMemberInfoVerify()
          } else {
            this.toastFailure(this.messageErrorVerifyInfo)
          }
        } else {
          this.toastFailure(this.messageErrorUser)
        }
      } else {
        const params = {
          id: info._id,
          status: 'verify',
        }
        await this.verifyContactInformation(params)
        if (this.statusVerifyInfo) {
          this.toastSuccess(this.$t('partnerView.toastApproveUserInfoSuccess'))
          await this.fetchListMemberInfoVerify()
        } else {
          this.toastFailure(this.messageErrorVerifyInfo)
        }
      }
    },

    async fetchListMemberInfoVerify() {
      const params = {
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field === 'createdAt' ? 'contactInfo.createdAt' : this.sortTerm.field
          }`,
        }),
        page: this.currentPage,
        size: this.pageLength,
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.statusSelected && { status: this.statusSelected.value }),
        ...(this.rangeDates && this.rangeDates),
      }
      await this.fetchListMemberInformation(params)
      if (!this.statusVerifyInfo) {
        this.toastFailure(this.messageErrorVerifyInfo)
      }
    },
  },
}

</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

.table-user-info {
  .column-product-link {
    min-width: 300px;
  }

  .width-column-100{
    min-width: 100px;
  }

  .width-column {
    min-width: 200px;
  }

  .crop-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>

<style lang="scss" scoped>
.contact-info-ticket {
  border-radius: 32px;
  background-color: #fff;
}
.filter-control {
  border-radius: 32px 32px 0 0;
}

.btn-filter-search {
  border-radius: 0 0.625rem 0.625rem 0;
}

.status-select {
  border: 1px solid #e0e0e5;
  border-radius: 0.625rem;
}

.select-search-custom {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.content-product-link {
  width: 400px;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-outline {
  background-color: #fff;
  color: #fab345;
  border-color: #fab345;
}

.input-height {
  height: 40px;
}

.text-status {
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.not-found {
    color: #babfc7;
    background-color: #babfc71a;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.img-store-screenshot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 70px;
  background-color: #cccccc6e;
  margin-right: 4px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .gallery-item-info {
    display: none;
  }

  &:hover .gallery-item-info {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ccccccb3;
    cursor: pointer;
  }
}
</style>
