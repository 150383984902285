import { render, staticRenderFns } from "./NoDataTableImage.vue?vue&type=template&id=297d1a75&scoped=true&"
import script from "./NoDataTableImage.vue?vue&type=script&lang=js&"
export * from "./NoDataTableImage.vue?vue&type=script&lang=js&"
import style0 from "./NoDataTableImage.vue?vue&type=style&index=0&id=297d1a75&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "297d1a75",
  null
  
)

export default component.exports