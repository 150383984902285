<template>
  <div class="d-flex align-items-center justify-content-center flex-column">
    <b-img
      fluid
      alt="No account"
      class="img"
      :src="require('@/assets/images/common/no-data-table.svg')"
    />
    <!--    <b-button-->
    <!--      variant="primary"-->
    <!--      class="new-acc-btn"-->
    <!--      :to="{ name: 'request-new-account' }"-->
    <!--    >-->
    <!--      New Account-->
    <!--    </b-button>-->
  </div>
</template>

<script>
import {
  // BButton,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    // BButton,
    BImg,
  },
}
</script>

<style lang="scss" scoped>
  .img {
    max-width: 228px;
    max-height: 170px;
  }

  .new-acc-btn {
    margin-top: 10px;
  }
</style>
