<template>
  <div>
    <b-sidebar
      v-model="show"
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="onHidden"
    >
      <template>
        <!-- Header -->
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
        >
          <h5
            class="mb-0"
          >
            {{ $t('partnerView.titleRejectReason') }}
          </h5>

          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="onHidden"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          ref="refFormObserver"
          v-slot="{ invalid }"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @reset.prevent="resetForm"
          >
            <template>
              <!-- Reason -->
              <validation-provider
                #default="{ errors }"
                name="reason"
                rules="required"
              >
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      {{ $t('productLinkPage.titleColumnReason') }}
                    </label>
                  </div>
                  <quill-editor
                    v-model.trim="description"
                    ref="QuillEditor"
                    id="quil-content"
                    size="20"
                    :options="editorOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>

              </validation-provider>
            </template>
            <!-- Form Actions -->
            <div
              class="d-flex mt-2"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid"
                @click.prevent="rejectedProductLinks"
              >
                {{ $t("forgotPW.btnSendLinkReset") }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import {
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import Ripple from 'vue-ripple-directive'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { toastification } from '@core/mixins/toast'
import { quillEditor } from 'vue-quill-editor'
import { createNamespacedHelpers, mapGetters } from 'vuex'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import i18n from '@/libs/i18n'
// eslint-disable-next-line import/extensions

const { mapActions } = createNamespacedHelpers('partnerShip')
const { mapActions: mapActionsReferralProgram } = createNamespacedHelpers('referralProgram')

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,

    // 3rd party packages
    // quillEditor,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  mixins: [generalConfigsMixin, toastification],
  props: {
    showSideBar: {
      type: Boolean,
      default: false,
      required: true,
    },

    infoUserDetail: {
      type: Object,
      required: true,
    },

    productLinkReject: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      description: '',
      show: false,

      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
        placeholder: i18n.t('partnerView.placeholderInsertTextHere'),
      },

      // validate
      required,
    }
  },

  computed: {
    ...mapGetters({
      loadingPartnerShip: 'partnerShip/loading',
      statusVerifyInfo: 'partnerShip/status',
      messageErrorVerifyInfo: 'partnerShip/message',
      statusProductMember: 'referralProgram/status',
      messageErrorUser: 'referralProgram/message',
    }),
  },

  watch: {
    showSideBar(show) {
      this.show = show
    },
  },

  methods: {
    ...mapActions(['verifyContactInformation']),
    ...mapActionsReferralProgram(['handleProductLink']),

    onHidden() {
      this.resetForm()
      this.$emit('update:show-side-bar', false)
    },

    resetForm() {
      this.description = ''
    },

    async rejectedProductLinks() {
      const productLinkReviewing = this.productLinkReject.find(item => item.status === 'reviewing')
      if (productLinkReviewing) {
        const paramsRejected = {
          id: this.infoUserDetail.localUserId,
          action: 'reject',
          productURL: productLinkReviewing?.productUrl,
          rejectedReason: this.description,
        }
        await this.handleProductLink(paramsRejected)
        if (this.statusProductMember) {
          const params = {
            // eslint-disable-next-line no-underscore-dangle
            id: this.infoUserDetail._id,
            status: 'reject',
            rejectedReason: this.description,
          }
          await this.verifyContactInformation(params)
          if (this.statusVerifyInfo) {
            this.toastSuccess(this.$t('partnerView.toastRejectUserInfoSuccess'))
            this.$emit('fetch-list-member-info-verify')
            this.onHidden()
          } else {
            this.toastFailure(this.messageErrorVerifyInfo)
          }
        } else {
          this.toastFailure(this.messageErrorUser)
        }
      } else {
        const params = {
          // eslint-disable-next-line no-underscore-dangle
          id: this.infoUserDetail._id,
          status: 'reject',
          rejectedReason: this.description,
        }
        await this.verifyContactInformation(params)
        if (this.statusVerifyInfo) {
          this.toastSuccess(this.$t('partnerView.toastRejectUserInfoSuccess'))
          this.$emit('fetch-list-member-info-verify')
          this.onHidden()
        } else {
          this.toastFailure(this.messageErrorVerifyInfo)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    height: 200px;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
