<template>
  <div class="contact-info-container">
    <member-contact-info-ticket />
  </div>
</template>

<script>
import MemberContactInfoTicket
  from '@/views/partner-management/member-verify-information/components/MemberContactInfoTicket.vue'

export default {
  name: 'MemberContactInfoTable',
  components: { MemberContactInfoTicket },

  component: {
    MemberContactInfoTicket,
  },
}
</script>

<style scoped>

</style>
